<template>
  <v-card
    outlined
    v-if="passportList"
    class="elevation-1 rounded-0"
  >
    <BaseAlert
      v-if="!isSave"
      text="Внимание! После сохранения отредактировать данный документ невозможно"
      type="info"
    />
    <v-card-title class="d-flex justify-space-between">
      {{ detail.id ? 'Порубочный билет #' + detail.id : 'Порубочный билет' }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <BasePassportSelect
            :disabled="isSave"
            v-model="detail.passport_id"
            :passport-list="passportList"
            class="mb-5"
          />
          <BaseDialogTableSelect
            :disabled="isSave"
            v-if="passportLocal.properties"
            v-model="detail.nasajdeniya"
            :passport="passportLocal"
            class="mb-5"
          />
          <v-text-field
            :disabled="isSave"
            v-model="detail.felling_ticket_id"
            color="secondary"
            label="Номер порубочного билета"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.declarant_full_name"
            color="secondary"
            label="ФИО Заявителя"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.declarant_address"
            color="secondary"
            label="Адрес проживания"
            dense
            outlined
          ></v-text-field>
          <BaseDatePicker
            :disabled="isSave"
            v-model="detail.date_of_issue"
            label="Дата выпуска"
          />
          <v-text-field
            :disabled="isSave"
            v-model="detail.object_address"
            color="secondary"
            label="Адрес объекта уничтожения"
            dense
            outlined
          ></v-text-field>
          <v-textarea
            :disabled="isSave"
            v-model="detail.action_type"
            color="secondary"
            label="Вид работ"
            dense
            outlined
          ></v-textarea>
          <v-text-field
            :disabled="isSave"
            v-model="detail.payment"
            color="secondary"
            label="Номер платежного поручения и дата"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.survey_act_id"
            color="secondary"
            label="Номер акта обследования"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.trees_to_cut"
            color="secondary"
            label="Вырубить деревьев шт"
            dense
            outlined
            type="number"
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.trees_to_trim"
            color="secondary"
            label="Обрезать деревьев шт"
            dense
            outlined
            type="number"
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.trees_to_destroy"
            color="secondary"
            label="Уничтожить деревьев шт, кв.м травянистых растений"
            dense
            outlined
            type="number"
          ></v-text-field>
          <div class="d-flex">
            <v-text-field
              class="mr-2"
              v-model="detail.price"
              color="secondary"
              label="Расчет размера платы"
              dense
              disabled
              outlined
            ></v-text-field>
            <v-btn
              :disabled="isSave"
              v-if="detail.trees_to_destroy"
              @click="isDialog = !isDialog"
              small
            >
              <v-icon class="mr-2">mdi-calculator</v-icon>
              Расчёт компенсационной выплаты зеленых насаждений
            </v-btn>
            <PriceCalc
              v-model="isDialog"
              :price.sync="detail.price"
              :trees_to_destroy="detail.trees_to_destroy * 1"
            />
          </div>
          <v-text-field
            :disabled="isSave"
            v-model="detail.executer_full_name"
            color="secondary"
            label="Фио и телефон исполнителя"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.executive_full_name"
            color="secondary"
            label="Фио должностного лица"
            dense
            outlined
          ></v-text-field>

          <div class="d-flex justify-center">
            <v-btn
              v-if="detail.nasajdeniya.length && detail.id"
              class="ml-2"
              large
              color="success"
              @click="dialogReport = !dialogReport"
            >
              <v-icon class="mr-2">mdi-download</v-icon>
              Скачать ПБ
            </v-btn>
            <v-btn
              v-if="detail.request_felling_ticket_id"
              class="ml-2"
              large
              color="info"
              :to="{name: 'requestFellingTicketDetail', params: {id: detail.request_felling_ticket_id}}"
            >
              <v-icon class="mr-2">mdi-arrow-top-right</v-icon>
              Перейти в заявление
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <BaseReportPanel
        v-model="dialogReport"
        report-type="nasajdeniya"
        :selected-items="detail.nasajdeniya"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import BaseDatePicker from '@/components/base/BaseDatePicker'
import downloadFile from '@/components/mixins/downloadFile'
import PriceCalc from '@/components/views/account/fellingTicket/detail/detailView/PriceCalc'
import BasePassportSelect from '@/components/base/forms/BasePassportSelect'
import BaseDialogTableSelect from '@/components/base/BaseDialogTableSelect'
import nasajdeniyaSelect from '@/components/mixins/nasajdeniyaSelect'
import BaseReportPanel from '@/components/base/BaseReportPanel'
import BaseAlert from '@/components/base/UI/BaseAlert'

export default {
  mixins: [formatters, downloadFile, nasajdeniyaSelect],
  components: { BaseAlert, BaseDialogTableSelect, BasePassportSelect, PriceCalc, BaseDatePicker, BaseReportPanel},
  name: 'DetailView',
  data () {
    return {
      isDialog: false,
      dialogReport: false
    }
  }
}
</script>

<style scoped>

</style>
