<template>
  <BaseDetail
    v-if="detail"
    :is-loading="loading">
    <template #header>
      <BaseHeader
        @save="saveWithMessages"
        @delete="handleDelete"
        @close="handleClose"
        :save-btn="!detail.id"
      />
    </template>
    <template #content>
      <DetailView
        v-if="detail"
        v-model="detail"
        ref="content"
      />
    </template>
  </BaseDetail>
</template>

<script>
import detail from '@/components/mixins/detail'
import BaseDetail from '@/components/base/BaseDetail'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import DetailView from './detail/DetailView'

export default {
  mixins: [detail],
  name: 'Detail',
  components: { BaseHeader, BaseDetail, DetailView },
  computed: {
    controllerName () {
      return 'felling-ticket'
    }
  },
  watch: {
    '$route.query': {
      async handler (newVal) {
        if (newVal.hasOwnProperty('request_felling_ticket_id')) {
          return this.$nextTick(async () => {
            if (!this.detail.id) {
              this.isLoading.data = true
              this.detail.requestFellingTicket = await this.$store.dispatch('server/get', {
                url: 'request-felling-ticket' + '/detail/' + this.$route.query.request_felling_ticket_id
              })
              this.detail.fillFromRequestFellingTicket()
              this.isLoading.data = false
            }
          })
        }
        return true
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async saveWithMessages () {
      if (await this.save() && this.detail.file) {
        return this.$store.dispatch('systemMessages/info', 'Сформирован ' + this.detail.file.name, { root: true })
      }
    },
    handleClose () {
      this.$router.push({ name: 'fellingTicket' })
    }
  }
}
</script>

<style scoped>

</style>
