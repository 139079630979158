<template>
  <v-dialog
    persistent
    max-width="700px"
    v-model="value"
  >
    <v-card>
      <v-card-title>Расчёт компенсационной выплаты зеленых насаждений</v-card-title>
      <v-card-text>
        <div class="subtitle-2">Количество зеленых насаждений, подлежащих уничтожению {{trees_to_destroy}}</div>
        <div
          class="title"
          v-if="greenClass"
        > Компенсационная выплата {{priceRestorative}} рублей
        </div>
      </v-card-text>
      <v-card-text>
        <v-select
          label="Классификация зеленых насаждений"
          dense
          outlined
          :items="classification"
          v-model="greenClass"
          item-text="title"
          return-object
          clearable
        >
        </v-select>
        <div v-if="greenClass">
          <div>Коэффициент поправки на местоположение зеленых насаждений на территории поселения {{cmFactor}}</div>
          <div>Коэффициент, учитывающий затраты на проектирование (по необходимости) {{cpFactor}}</div>
          <v-btn
            class="mb-4 mt-4"
            small
            @click="showFactor = !showFactor"
          > {{showFactor ? 'Скрыть' : 'Изменить коэффициенты' }}
          </v-btn>
          <div v-show="showFactor">
            <v-text-field
              v-model="cmFactor"
              label="Коэффициент поправки на местоположение зеленых насаждений на территории поселения"
              outlined
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="cpFactor"
              label="коэффициент, учитывающий затраты на проектирование (по необходимости)"
              outlined
              dense
              type="number"
            ></v-text-field>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex pb-10">
        <v-btn
          v-if="priceRestorative"
          class="primary"
          @click="handleUpdatePrice()"
        >Применить
        </v-btn>
        <v-btn
          @click="isDialogOpen = false"
        >Закрыть окно
        </v-btn>
        <v-btn
          v-if="price"
          @click="handleClear"
        >Очистить цену
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PriceCalc',
  props: {
    value: Boolean,
    price: String,
    trees_to_destroy: Number
  },
  data () {
    return {
      showFactor: false,
      cpFactor: 1,
      cmFactor: 1,
      greenClass: null,
      classification: [
        {
          id: 1,
          title: 'Субтропические ценные растения',
          landingMethod: 1896,
          landingMaterial: 20000,
          care: 912,
          cvd: 10
        },
        {
          id: 2,
          title: 'Деревья субтропические',
          landingMethod: 1896,
          landingMaterial: 12500,
          care: 912,
          cvd: 10
        },
        {
          id: 3,
          title: 'Деревья хвойные',
          landingMethod: 1896,
          landingMaterial: 9500,
          care: 912,
          cvd: 10
        },
        {
          id: 4,
          title: 'Деревья лиственные 1-й группы',
          landingMethod: 1149,
          landingMaterial: 4500,
          care: 422,
          cvd: 7
        },
        {
          id: 5,
          title: 'Деревья лиственные 2-й группы',
          landingMethod: 1149,
          landingMaterial: 3000,
          care: 422,
          cvd: 5
        },
        {
          id: 6,
          title: 'Деревья лиственные 3-й группы',
          landingMethod: 1149,
          landingMaterial: 2000,
          care: 422,
          cvd: 3
        },
        {
          id: 7,
          title: 'Кустарники',
          landingMethod: 414,
          landingMaterial: 300,
          care: 306,
          cvd: 1
        },
        {
          id: 8,
          title: 'Газон, естественный травяной покров, 1 кв. м',
          landingMethod: 211,
          landingMaterial: 10,
          care: 270,
          cvd: 1
        },
        {
          id: 9,
          title: 'Цветники, 1 кв. м',
          landingMethod: 181,
          landingMaterial: 630,
          care: 452,
          cvd: 1
        }
      ],
      newPrice: null
    }
  },
  computed: {
    isDialogOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    priceRestorative () {
      if (this.greenClass !== null) {
        return (this.greenClass.landingMethod + this.greenClass.landingMaterial +
          this.greenClass.care * this.greenClass.cvd) * this.cmFactor * this.trees_to_destroy * this.cpFactor
      }
    }
  },
  methods: {
    handleUpdatePrice () {
      this.newPrice = String(this.priceRestorative + '.00')
      this.$emit('update:price', this.newPrice)
      this.isDialogOpen = false
    },
    handleClear () {
      this.$emit('update:price', '')
    }
  }
}
</script>

<style scoped>

</style>
